body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #0B0A0A;
}

main {
    flex-grow: 1;
}

footer {
    margin-top: auto;
}
.active {
     text-decoration: underline!important;
}


@font-face {
    font-family: 'HelveticaNeue';
    src: url('../public/Helvetica.woff2') format('woff2');
    font-weight: 300; /* Normal weight */
    /* You can also include additional font properties here, like font-weight and font-style */
}
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../public/helvetica-neue-light-italic.woff2') format('woff2');
    font-weight: 100; /* Thin weight */
}
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../public/helveticaneue-thin.woff2') format('woff2');
    font-weight: 50; /* Thin weight */
}
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../public/helvetica-neue-roman-italic.woff2') format('woff2');
    font-weight: 200; /* Thin weight */
}

/* For WebKit (Chrome, Safari, etc.) */
/* Hide scrollbar but allow scrolling */
::-webkit-scrollbar {
    width: 0px;
    position:relative;
    /* Adjust as needed */
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
    /* Set slightly transparent background */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255,
        255,
        255, 0.5);
    /* Customize the color of the scrollbar thumb */
    border-radius: 5px;
    /* Rounded corners for the scrollbar thumb */
}


body {
    overflow-x: hidden;
}

/* Ensure scrollbar only appears when necessary */
.container {
    overflow-y: auto;
}